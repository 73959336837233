$color-primary: black;
$color-secondary: #bbb;
$color-hover-internal-link: rgba(255, 219, 0, 1);
$color-hover-external-link: #ddd;
$color-border: hsl(0, 0%, 85%);
$color-box-background: #f2f2f2;
$color-primary-background: white;
$color-secondary-background: white;
$border-radius: 4px;
$font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
  sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

html{
  font-size: 15px;
}

body {
  //code for p5.js
  min-height: 100%;
  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
  /* These two lines are counted as one :-)       */
  margin: 0;
  padding: 0;

  box-sizing: content-box;
  font-family: $font-family;
  // margin: 0 auto;
  margin: 0;
  line-height: 1.6;
  // padding: 4vh 6vw;
  // overflow-x: hidden;
  // overflow: hidden;

  color: $color-primary;
  font-size: 0.6rem;
  // max-width: 63em;

  @media (min-width: 820px) {
    font-size: 0.85rem;
  }
}

.wrapper {
  display: flex;
  // overflow: auto;
}

#left, 
#right {
  margin: 0;
  width: 50%;
  box-sizing: border-box;
  // height: 100vh;
  .primary {
    padding-top: 2rem;
  }
  .secondary {
    padding-top: 0;
  }
  .primary, .secondary {
    padding-bottom: 0;
  }
}

#left {
  // border-right: 1px solid #dadada;
  .primary, .secondary {
    padding-right: 1rem;
    padding-left: 2rem;
  }
  img {
    max-height: 65vh;
  }
}

#right {
  position: fixed;
  right: 0px;
  .primary, .secondary {
    padding-right: 2rem;
    padding-left: 1rem;
  }
  img {
    max-height: 35vh;
  }
  .gallery {
    // max-width: 70%;
  }
}

.backlinks{
  padding: 1rem 0;
  a.internal-link {
    color: $color-secondary;
    border-color: $color-secondary;
    &:focus {
      color: $color-primary !important;
      border-color: $color-primary !important;
      background: $color-hover-internal-link;
    }
  }
}

footer{
  padding: 1rem 0 1rem 0;
}

p, .media-p{
  margin: 0;
  padding: 0 0 1rem 0;
}

time {
  // display: block;
  // color: $color-primary;
}

h1{
  margin: 0;
  font-size: 1.5em;
  line-height: 1.4 !important;
  padding: 0 0 1rem 0;
}

h2, .font-size-small, .tag{
  font-size: 0.8em;
}

h2{
  margin: 0;
  padding: 0 0 0.3rem 0;
  font-weight: normal;
}

hr{
  height: 1px;
  border: none;
  background-color: red;
}

table{
  width: 100%;
  table-layout: fixed;
  border-spacing: 0px;
}

td{
  padding: 0px;
  vertical-align: top;
}

ul, ol{
  // margin: 0;
  margin-block-start: 0;
  padding: 0 0 0 1.2rem;
}

ul{
  list-style-type: disc;
}

// h4, h5, h6{
//   font-size: 1em;
//   line-height: 1.6 !important;
//   margin: 0;
//   padding: 0 0 1rem 0;
//   font-weight: normal;
//   color: red;
// }

img {
  max-width: 100%;
  // width: 100%;
  display: block;
  // margin: 0 auto;
  // margin: 0 auto 30px 0;

  // max-height: 65vh;

  // border-radius: $border-radius;
  //perque totes les imatges passin per sobre del gradient
  position: relative;
  // z-index: 10;
}

blockquote {
  margin: 0;
  // padding: 1rem 0 0 0;
  padding: 0;
  display: inline-block;
  width: 100%;
  // background: $color-secondary-background;
  color: $color-secondary;
  a:link, a:visited, a:visited:after, a:active, a:after, a.internal-link:link, a.internal-link:visited, a.internal-link:active {
    color: $color-secondary;
    border-color: $color-secondary;
  }
  a.internal-link:hover, a.internal-link:focus {
    color: $color-primary;
    border-color: $color-primary;
  }
  a:hover, a:focus {
    color: #00f;
    border-bottom: 1px solid #00f;
    background: $color-hover-external-link;
  }
  a:hover:after, a:focus:after {
    color: #00f;
  }
}

// hr {
//   width: 100%;
//   border: 0;
//   height: 1px;
//   margin: 1.5em 0;
//   background: $color-border;
// }

a {
  // transition: background 200ms;
  // padding: 0 0.1em;
  &:link, &:visited, &:active {
    text-decoration: none;
    color: $color-primary;
    background: transparent;
    border-bottom: 1px solid #00f;
  }
  &:hover, &:focus {
    color: #00f;
    border-bottom: 1px solid #00f;
    background: $color-hover-external-link;
  }
  &:after, &:visited:after {
    position: relative;
    top: -0.5em;
    font-size: 0.7em;
    content: " ↗";
    color: #00f ;
  }
  &.internal-link:after, &.internal-link-disconnected:after, &.img-link:after {
    content: "";
  }
}

a.disable-link-style {
  border-bottom: inherit;
  color: inherit;
  &.disable-link-style:after {
    color: inherit;
  }
}

a.internal-link {
  &:link, &:visited, &:hover, &:active {
    color: $color-primary;
    background: transparent;
    border-color: $color-primary;
  }
  &:focus {
    color: $color-primary;
    background: $color-hover-internal-link;
    border-color: $color-primary;
    img {
      opacity: 0;
    }
  }
}

a.internal-link-disconnected{
  border-color: $color-primary;
  &:focus {
    color: $color-primary;
    background: $color-hover-external-link;
  }
}

.internal-link img{
  // width: 100px;
  // width: 300px;
  width: 100%;
  // height: 50px;
  margin: 0px;
  display: block;
  // padding: 5px;
  // mix-blend-mode: luminosity;
}

a.img-link {
  margin: 0;
  padding: 0;
  display: block;
  border: none;
  width: fit-content;
  background: transparent;
}

.highlight-backlink {
  color: $color-primary !important;
  border-color: $color-primary !important;
  background: $color-hover-internal-link !important;
  img {
    opacity: 0;
  }
}

*:focus {
  // background: #ffe8bc !important;
  // color: black !important;
  outline: none;
}

.gallery{
  display: flex;
  flex-direction: column;
  // padding: 1rem 0 2rem 0;
  padding-bottom: 1rem;
}

.gallery a{
  border-bottom: none;
  // margin: 0px 5px 0 0;
  // padding: 0;
  // margin: 2px;
  margin: 0 4px 4px 0;
  width: 25%;
}

.gallery-row{
  display: flex;
  width: 100%;
}

nav {
  // margin: 1em 0 3em;
  margin-bottom: 2rem;
  // border-bottom: 1px solid #dadada;
}

// #notes-entry-container {
//   display: grid;
//   grid-gap: 2em;
//   grid-template-areas:
//     "content"
//     "side";

//   @media (min-width: 700px) {
//     grid-template-columns: 3fr 1fr;
//     grid-template-areas: "content side";
//   }
// }

// .backlink-box {
//   background: $color-box-background;
//   padding: 1rem;
//   border-radius: $border-radius;
//   // font-size: 0.8em;
// }

// code {
//   background: #f5f5f5;
//   padding: 0.1em 0.2em;
//   border-radius: 4px;
// }

.invalid-link {
  color: #444444;
  cursor: help;
  background: #fafafa;
  padding: 0 0.1em;
}

.invalid-link-brackets {
  color: #ccc;
  cursor: help;
}

#gradient {
  //https://cssgradient.io/
  background: white;
  background: linear-gradient(0deg, white 0%, rgba(255,255,255,0) 100%);
  // background: red;
  height: 50vh;
  width: 100%;
  position: fixed;
  bottom: 0px;
  // right: 0px;
  z-index: 5;
}

#file{
  height: 100%;
  width: 50%;
  display: block;
  overflow: hidden;
  position: absolute;
  right: 0;
}

.photo-full{
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.photo-header{
  margin-bottom: 2rem;
}

.primary{
  // padding-top: 2rem !important;
  // background: $color-primary-background;
}

.secondary{
  // padding: 1rem 0 0 0;
  // background: $color-secondary-background;
  color: $color-secondary;
}

.media{
  padding:56.25% 0 0 0;
  position:relative;
  // z-index: 10;
}

.media-iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#canvas-container {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

::selection {
  background-color: $color-hover-external-link;
}

#line{
  stroke: $color-hover-internal-link;
  stroke-width: 2;
  stroke-linecap: round;
}

#line-container {
  position: fixed;
  overflow: visible!important;
  pointer-events: none!important;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
}


/* LQIP (Low Quality Image Placeholder)/blurry image placeholder/Blur up image technique */
/* https://github.com/aFarkas/lazysizes */

.blur-up {
  // -webkit-filter: blur(10px);
  // filter: blur(10px); 
  // transition: filter 400ms, -webkit-filter 400ms; 
  image-rendering: pixelated;
}

.blur-up.lazyloaded {
  // -webkit-filter: blur(0);
  // filter: blur(0); 
  image-rendering: auto;
}


/* media query breaking points via https://gist.github.com/gokulkrishh/242e68d1ee94ad05f488 */

@media (min-width: 481px) and (max-width: 767px) {


}

@media (min-width: 320px) and (max-width: 480px) {
  body {
    font-size: 1rem;
  }

  #right, #line-container {
    display: none;
  }

  #left {
    width: 100%;
    .primary, .secondary {
      padding: 1rem 1rem 0 1rem;
    }
    img {
      max-height: initial;
    }
  }

}

@media screen and (orientation: portrait) {


}

@media screen and (orientation: landscape) {

}